<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cliente Web - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
           
                <b-col md="2">
                  <b-form-group label="Tipo de Documento :">
                    <b-form-select disabled v-model="customer.document_type" :options="document_type"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Nro Documento :">
                    <b-input-group>
                      <b-form-input disabled v-model="customer.document_number" class="form-control" ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
         
                <b-col md="7">
                  <b-form-group label="Nombres / Razón Social:">
                    <b-form-input type="text" disabled v-model="customer.name" placeholder="Ingrese su nombre"></b-form-input>
                  </b-form-group>
                </b-col>
      
                <b-col md="6">
                  <b-form-group label="Email :">
                    <b-form-input disabled type="email"  ref="email" v-model="customer.email" ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Contraseña:">
                    <b-form-input disabled type="password"  v-model="customer.password"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Teléfono :">
                    <b-form-input disabled type="text"  v-model="customer.phone"></b-form-input>
                  </b-form-group>
                </b-col>

              
       
                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select disabled v-model="customer.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>
           


                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/cliente-web/listar' }" append >REGRESAR</b-link>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'

export default {
  name: "ClientEdit",
  props: ["id_customer"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  
  data() {
    return {
      module:'Customer',
      isLoading: false,
      customer: {
        id_customer: "",
        document_type: "1",
        document_number: "",
        name: "",
        phone: "",
        password: "",
        email: "",
        state: 1,
      },

      contries: [],
      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        document_type: false,
        document_number: false,
        name: false,
      },

      error_document_number:'',
      validate: false,
    };
  },
  mounted() {
    this.ViewClient();
  },
  methods: {
    ViewClient,
    EditClient,
    Validate,
    
    SearchClient,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function SearchClient() {
  let me = this;
  if (me.customer.document_type == 1) {
      if (me.customer.document_number.length == 8) {
        me.errors.document_number = false;
        ApiQuery.SearchDni(me.customer.document_number).then((data) => {
          if (data.status == 200) {
            me.customer.name = data.razon_social;
            me.customer.address = data.direccion;
          }else{
            me.customer.name = '';
            me.customer.address = '';
          }
            
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 8 digitos';
      }
  }

  if (me.customer.document_type == 6) {
      if (me.customer.document_number.length == 11) {
        me.errors.document_number = false;
        ApiQuery.SearchRuc(me.customer.document_number).then((data) => {
          if (data.status == 200) {
            me.customer.name = data.razon_social;
            me.customer.address = data.direccion;
          }else{
            me.customer.name = '';
            me.customer.address = '';
          } 
        });
      }else{
        me.errors.document_number = true;
        me.error_document_number = 'El nro de ducumento debe contener 11 digitos';
      }
  }
  
}

//ver usuario
function ViewClient() {
  let me = this;
  let id_customer = je.decrypt(me.id_customer);
  let url = me.url_base + "customer/view/" + id_customer;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: 3, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.customer.id_customer = response.data.result.id_customer;
        me.customer.document_type = response.data.result.document_type;
        me.customer.document_number = response.data.result.document_number;
        me.customer.name = response.data.result.name;
        me.customer.phone = response.data.result.phone;
        me.customer.password = "";
        me.customer.email = response.data.result.email;
        me.customer.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditClient() {

  let me = this;
  let url = me.url_base + "customer/edit";
  let data = me.customer;

  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module, role: 3,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.customer.password = "";
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else if(response.data.status == 200) {
          Swal.fire({ icon: 'warning', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {

 this.validate = false;

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar los datos del cliente?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditClient();
      }
    });

  }

}
</script>
